<!-- Part of the SPARKL educational activity system, Copyright 2019 by Pepper Williams -->
<template>
	<v-container class="k-login-container" fill-height>
		<v-spacer/>
		<div class="k-login-wrapper">
			<div v-if="show_login_close_btn" style="position:absolute;right:-9px;top:-9px;"><v-btn icon color="#999" @click="cancel_login"><v-icon large>fas fa-circle-xmark</v-icon></v-btn></div>
			<div class="k-login-logo-wrapper"><v-spacer/>
				<img class="k-login__logo-img" :src="agency_logo_img_src">
				<div class="k-login__logo-text" v-html="site_config.login_logo_text" translate="no"></div>
			<v-spacer/></div>
			<div v-if="login_msg" class="k-login-msg" v-html="login_msg"></div>
			<div v-if="friendly_login_error!=null" class="k-login-error" v-html="friendly_login_error"></div>
			<div class="k-login-fields-wrapper">
				<div v-if="site_config.allow_password_login=='yes'">
					<v-text-field @keyup="keyup" ref="email_field" class="mt-3" background-color="#eee" outlined hide-details prepend-inner-icon="fa fa-envelope" label="Email Address" v-model="email"></v-text-field>
					<v-text-field @keyup="keyup" class="mt-4" background-color="#eee" outlined password hide-details prepend-inner-icon="fa fa-key" :type="show_password ? 'text' : 'password'" :append-icon="show_password ? 'fas fa-eye' : 'fas fa-eye-slash'" @click:append="show_password = !show_password" label="Password" v-model="password"></v-text-field>

					<div class="d-flex align-center mt-4">
						<div v-if="site_config.login_method=='cureum'" style="line-height:24px">
							<v-btn v-if="this.site_config.show_create_account_on_login=='yes'&&!site_config.down_until" text small color="primary" class="k-tight-btn k-nocaps-btn xma-0" style="font-weight:normal; font-size:14px;" @click="show_create_account_dialog=true">Create account</v-btn>
							<v-btn v-if="this.site_config.show_forgot_password_btn=='yes'&&!site_config.down_until" text small color="primary" class="k-tight-btn k-nocaps-btn xma-0" style="font-weight:normal; font-size:14px;" @click="forgot_password">Forgot password?</v-btn>
						</div>
						<v-spacer/>
						<v-btn @click="sign_in" color="primary" class="ml-2">Sign In<v-icon small class="ml-2">fas fa-right-to-bracket</v-icon></v-btn>
					</div>
				</div>

				<div v-if="site_config.allow_google_login=='yes'&&!site_config.down_until" class="d-flex mt-4 pb-2" :style="site_config.allow_password_login=='no'?'height:52px;padding-top:0px;':'height:64px;border-top:1px solid #aaa;padding-top:16px;'"><v-spacer/><div id="g_signin_admin"></div><v-spacer/></div>

				<div v-if="site_config.allow_msft_login=='yes'&&!site_config.down_until" class="d-flex mt-4 pt-4 pb-2 center justify-center align-center" style="border-top:1px solid #aaa; height:64px;">
					<v-btn class="mt-2 k-nocaps-btn k-tight-btn" large color="#555" dark @click="msft_sign_in" style="width:100%; height:50px; border-radius: 4px; position:relative;">
						<img src="../../images/msftlogo.png" alt="Microsoft Logo" style="width:42px; height:42px; border-radius:3px; left:-16px; top:-11px; position:absolute;"/>
						<div style="padding-left:46px; font-size:18px;">Sign in with Microsoft</div>
					</v-btn>
				</div>

			</div>
		</div>
		<v-spacer/>
		<div class="k-copyright" @click="show_password_login">{{ site_config.copyright_text }} &nbsp; (v{{$store.state.app_version}})</div>

		<CreateAccount v-if="show_create_account_dialog" @dialog_cancel="show_create_account_dialog=false" />
	</v-container>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import CreateAccount from './CreateAccount'

export default {
	components: { CreateAccount },
	data() { return {
		email: '',
		password: '',
		show_password: false,
		show_create_account_dialog: false
	}},
	computed: {
		...mapState(['login_msg', 'login_error', 'site_config', 'google_client_id']),
		friendly_login_error() {
			if (this.login_error == 'ad_fail') {
				return 'Unknown email address or invalid password.'
			} else if (this.login_error == 'sis_fail' || this.login_error == 'ic_fail_password_error') {
				return 'Your email address could not be verified via your Student Information System.'
			} else if (this.login_error == 'henry_email_bad_password') {
				return 'Please sign in using the “Sign in with Microsoft” button below.'
			} else {
				return this.login_error
			}
		},
		agency_logo_img_src() {
			if (!this.site_config.agency_logo) return ''
			return this.site_config.agency_logo
		},
		showing_resource_link() {
			return (document.location.pathname.search(/\/reslink\/(.*)/) == 0)
		},
		show_login_close_btn() { return !this.showing_resource_link },
	},
	created() {
		// store reference to this component in vapp for use in redirect_to_login
		vapp.login_view_component = this
	},
	mounted() {
		// if we're using google signin, initialize it
		if (this.site_config.allow_google_login == 'yes') this.google_signin_initialize()

		// fill the "normal" login email field with the last-entered email
		if (this.site_config.allow_password_login == 'yes') {
			this.$nextTick(()=>{
				this.email = this.$store.state.lst.login_email
				$(this.$refs.email_field.$el).find('input').focus()
			})
		}
	},
	methods: {
		google_signin_initialize() {
			U.loading_start()
			// google sign in should be loaded via https://accounts.google.com/gsi/client in index.html
			// if it isn't loaded yet, try again in 100 ms
			if (typeof(google) == 'undefined' || typeof(google.accounts) == 'undefined') {
				setTimeout(()=>this.google_signin_initialize(), 100);
				console.log('re-try google.accounts.id.initialize')
				return;
			}
			U.loading_stop()

			// console.log('id', this.google_client_id)

			// api docs https://developers.google.com/identity/gsi/web/reference/js-reference
			google.accounts.id.initialize({
				client_id: sr("$1.apps.googleusercontent.com", this.google_client_id),
				callback: this.handleCredentialResponse,
			});
			
			google.accounts.id.renderButton(
				document.getElementById("g_signin_admin"),
				{ 
					theme: "filled_blue", 
					size: "large",
				} 
			);
			google.accounts.id.prompt(); // optionally, display the One Tap dialog
		},

		handleCredentialResponse(response) {
			//console.log("Encoded JWT ID token: " + response.credential);
			let id_token = response.credential
			if (id_token) {
				// pass id_token back to initialize_portal service, which will sign the user in
				vapp.initialize_app({google_id_token: id_token})
			} else {
				// TODO: what do we do here?
				alert('Google sign in handleCredentialResponse failed')				
			}
        },

		forgot_password() {
			this.$prompt({
				title: 'Forgot Password',
				text: 'Enter your email address and click the button below to send a one-time sign-in link to your email account. <b>This link will be valid for one hour.</b> After using the link to sign in, you can reset your password.',
				initialValue: this.email,
				acceptText: 'Send One-Time Sign-In Link',
				dialogMaxWidth: 540,
				focusBtn: true,		// focus on the accept btn when dialog is rendered
			}).then(fp_email => {
				U.loading_start()
				U.ajax('send_token_signin_email', {user_email: fp_email, token_duration: 60}, result=>{
					U.loading_stop()
					let msg
					if (result.status != 'ok') {
						if (result.status === 'bad_email') {
							msg = 'We couldn’t find an account for the email you entered.'
						} else {
							//msg = result.status
							msg = 'The one-time sign-in link could not be sent.'
						}
						console.log('Error in token signin ajax call: ' + result.status)	
					} else {
						msg = 'One-time sign-in link sent. <b>Please check your spam or junk folder</b> if you don’t receive the link within one minute, and remember that <b>the link will expire after one hour</b>.'
					}
					this.$alert(msg)
				})
			}).catch(n=>{console.log(n)}).finally(f=>{})
		},

		show_password_login() {
			this.$store.commit('set', [this.site_config, 'allow_password_login', 'yes'])
		},

		keyup(event) {
			if (event.code == 'Enter' || event.keyCode == 13) this.sign_in()
		},

		sign_in() {
			this.email = $.trim(this.email)
			this.password = $.trim(this.password)

			if (empty(this.email)) {
				this.$alert('Please enter your email address.')
				return
			}

			if (empty(this.password)) {
				this.$alert('Please enter your password.')
				return
			}

			// READY TO SIGN IN...

			// store email in local storage
			this.$store.commit('lst_set', ['login_email', this.email])
			vapp.initialize_app({
				email: this.email,
				password: this.password,
			})
		},

		// Microsoft OIDC sign in
		msft_sign_in() {
			// READY TO SIGN IN...
			window.location.href = '/src/saml/login.php'
		},

		cancel_login() {
			vapp.app_mode = 'main'
		},
	},
}
</script>

<style lang="scss">
.k-login-container {
	max-width:100vw;
	width:100vw;
}

.k-login-wrapper {
	position:relative;
	border-radius:8px;
	background:rgba(255, 255, 255, 0.9);
	padding:10px;

	.k-login-fields-wrapper {
		margin-top:10px;
		padding:2px 8px 8px 8px;
		border-radius:8px;
		background:rgba(255, 255, 255, 0.9);
	}

	.k-login__logo-text {
		font-family: var(--agency-name-font-family);
	}

	.k-login__logo-img {
		height:90px;
		// margin-left:5px;
		margin-top:20px;
	}
}

.k-login-msg {
	background-color:$v-blue-lighten-4;
	color:$v-blue-darken-4;
	border-radius:5px;
	padding:10px;
	margin: 10px 0 10px 0;
	max-width: 500px;
	font-size: 15px;
	line-height:20px;
}

.k-login-error {
	background-color:$v-red-lighten-4;
	color:$v-red-darken-4;
	border-radius:5px;
	padding:10px;
	margin: 10px 0 10px 0;
	max-width: 500px;
	font-size: 15px;
	line-height:20px;
}

.k-login-logo-wrapper {
	// width:500px;
	display:flex;
	justify-content:space-between;
	// align-items: center;
	font-size:40px;
	font-weight:bold;
	color: $v-doe-dark-pink;
	line-height:36px;
	margin-bottom:20px;
	position:relative;
}

.k-google_signin_btn {
	// cursor:pointer;
	// width:240px;
	// transform: scale(1.9);
}
</style>
